import './Body.scss';
import { Routes, Route} from 'react-router-dom';
import { routeAbout, routeAssets, routePrivacy, routeTermsOfUse, routeApps, routeGames, routeGamesId } from '../Envars';
import { lazy, Suspense } from 'react';

// Lazy load
const About = lazy(() => import('./Pages/About'));
const Games = lazy(() => import('./Pages/Games'));
const Assets = lazy(() => import('./Pages/Assets'));
const TermsOfUse = lazy(() => import('./Pages/TermsOfUse'));
const Privacy = lazy(() => import('./Pages/Privacy'));
const Apps = lazy(() => import('./Pages/Apps'));

function Body() {
  return (
    <main className='Body'>
      <Suspense fallback = {<div>Loading ...</div>}>
        <Routes>
          <Route path={routeAbout} element={<About></About>}/>
          <Route path={routeApps} element={<Apps></Apps>}/>
          <Route path={routeAssets} element={<Assets></Assets>}/>
          <Route path={routeTermsOfUse} element={<TermsOfUse></TermsOfUse>}/>
          <Route path={routePrivacy} element={<Privacy></Privacy>}/>
          <Route exact path={routeGames} element={<Games></Games>}/>
          <Route path={routeGamesId} element={<Games></Games>}/>
          <Route path="*" element={<Games></Games>}/>
        </Routes>
      </Suspense>
    </main>
  );
}

export default Body;