
// Routes
export const routeAbout = '/about';
export const routeTermsOfUse = '/tos';
export const routePrivacy = '/privacy';
export const routeAssets = '/assets';
export const routeApps = '/net';
export const routeGames = '/games';
export const routeGamesId = '/games/:id';

// Links to external pages
export const linkToInstagram = 'https://www.instagram.com/Protunnus/';
export const linkToYoutubeGames = 'https://www.youtube.com/channel/UCnQFz1JAUqMs-Mo_FN2otEA';
export const linkToYoutubeNet = 'https://www.youtube.com/channel/UCwIoktYY17RWhkLmdqEZYbg';
export const linkToFacebook = 'https://www.facebook.com/Protunnus';
export const linkToTwitter = 'https://twitter.com/Protunnus';

// Links to email
export const linkToEmail = 'mailto:protunnus26@outlook.com?subject = Feedback&body = Message';

// Header names
export const headerButtons = {
    games: 'GAMES',
    apps: 'NET',
    assets: 'ASSETS',
    termsOfUse: 'TERMS',
    privacy: 'PRIVACY',
};

// Footer names
export const copyrightText = '© 2020 Protunnus';
export const language = 'English';

// Analytics
export const GAmeasutementId = 'G-WP8QNHCE3W';

// Games Room
export const gamesList = [
    {
        id: 1,
        title: "Meteority",
        description: "Defend your house from falling meteorites",
        priceOnPc: "0.00 or donate",
        backColor: '#000000',
        trailer: 'https://www.youtube.com/embed/5KkoNdp7EV8',
        onPlayStore: {
            isLive: true,
            url: 'https://play.google.com/store/apps/details?id=com.Protunnus.Meteority',
        },
        onAppStore: {
            isLive: false,
            url: '',
        },
        onItchIo: {
            isLive: true,
            url: 'https://protunnus.itch.io/meteority',
        },
    },
    {
        id: 2,
        title: "Duplied",
        description: "You are your own enemy, play vs yourself",
        priceOnPc: "0.00 or donate",
        backColor: '#2C2C80',
        trailer: 'https://www.youtube.com/embed/-6g7FHybtQE',
        onPlayStore: {
            isLive: true,
            url: 'https://play.google.com/store/apps/details?id=com.Protunnus.Duplied',
        },
        onAppStore: {
            isLive: false,
            url: '',
        },
        onItchIo: {
            isLive: true,
            url: 'https://protunnus.itch.io/duplied',
        },
    },
];