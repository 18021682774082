import './App.scss';
import Header from './Components/Header';
import Body from './Components/Body';
import Footer from './Components/Footer';
import ReactGA from 'react-ga4';

import { BrowserRouter as Router} from 'react-router-dom';
import { GAmeasutementId } from './Envars';

function App() {

  
  ReactGA.initialize(GAmeasutementId);


  return (
    <Router>

      <Header></Header>
      <Body></Body>
      <Footer></Footer>
      
    </Router>
  );
}

export default App;