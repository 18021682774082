import { useMediaQuery } from "react-responsive";

export const FullScreen = ({ children }) => {
    const isFullScreen = useMediaQuery({minWidth: 950});
    return isFullScreen ? children : null;
};

export const MidScreen = ({ children }) => {
    const isFullScreen = useMediaQuery({maxWidth: 949, minWidth: 450});
    return isFullScreen ? children : null;
};

export const MiniScreen = ({ children }) => {
    const isFullScreen = useMediaQuery({maxWidth: 449});
    return isFullScreen ? children : null;
};