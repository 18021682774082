import './Footer.scss';
import icon_email from '../Assets/Images/icon_email.png';
import icon_facebook from '../Assets/Images/icon_facebook.png';
import icon_instagram from '../Assets/Images/icon_instagram.png';
import icon_twitter from '../Assets/Images/icon_twitter.png';
import icon_youtube from '../Assets/Images/icon_youtube.png';
import icon_youtubeGames from '../Assets/Images/icon_youtubeGames.png';
import icon_language from '../Assets/Images/icon_language.png';
import logo_microBlack_64 from '../Assets/Images/logo_microBlack_64.png';
import icon_more from '../Assets/Images/icon_more.png';

import { Link } from 'react-router-dom';
import { linkToFacebook, linkToInstagram, linkToTwitter, linkToYoutubeGames, linkToYoutubeNet, linkToEmail, routeAbout, language, copyrightText } from '../Envars';
import { FullScreen, MidScreen, MiniScreen } from '../reactResponsive';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';

function Footer() {

    // Offcanvas
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChangeLanguage = () => console.log('Change language');


  return (
    <div>
      <FullScreen>
        <footer className='stickyBottom fullScreen'>
      
          {/* Copyright text */}
          <Link to={routeAbout} className="iconLeft">
            <h3 className='rights'>{copyrightText}</h3>
            <img className="icon" src={logo_microBlack_64} alt='icon_instagram'></img>
          </Link>

          <div className='language'></div>
          
          {/* Contact and social media */}
          <a href={linkToEmail} className="iconRight"> <img className="icon " src={icon_email} alt='icon_email'></img> </a>
          <a href={linkToTwitter} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_twitter} alt='icon_twitter'></img> </a>
          <a href={linkToFacebook} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_facebook} alt='icon_facebook'></img> </a>
          <a href={linkToYoutubeGames} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_youtubeGames} alt='icon_youtubeGames'></img> </a>
          <a href={linkToYoutubeNet} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_youtube} alt='icon_youtube'></img> </a>
          <a href={linkToInstagram} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_instagram} alt='icon_instagram'></img> </a>

          {/* Change Language Button */}
          <button className="iconRight changeLanguageButton" onClick={handleChangeLanguage}>
            <h3 className='language'>{language}</h3>
            <img className="icon" src={icon_language} alt='icon_language'></img>
          </button>
          

        </footer>
      </FullScreen>
      <MidScreen>
        <footer className='stickyBottom midScreen'>
        
          {/* Copyright text */}
          <Link to={routeAbout} className='iconLeft'>
            <h3 className='rights'>{copyrightText}</h3>
          </Link>
          <div className='language'></div>
          <div className='language'></div>

          {/* Contact and social media */}
          <a href={linkToEmail} className="iconRight"> <img className="icon " src={icon_email} alt='icon_email'></img> </a>
          <a href={linkToTwitter} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_twitter} alt='icon_twitter'></img> </a>
          <a href={linkToFacebook} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_facebook} alt='icon_facebook'></img> </a>
          <a href={linkToYoutubeGames} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_youtubeGames} alt='icon_youtubeGames'></img> </a>
          <a href={linkToYoutubeNet} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_youtube} alt='icon_youtube'></img> </a>
          <a href={linkToInstagram} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_instagram} alt='icon_instagram'></img> </a>
          <div className='language'></div>

          {/* Change Language Button */}
          <button className="iconRight changeLanguageButton" onClick={handleChangeLanguage}>
            <img className="icon" src={icon_language} alt='icon_language'></img>
          </button>
          

        </footer>
      </MidScreen>
      <MiniScreen>
        <footer className='stickyBottom miniScreen'>
        
          <div className="offcanvasCustomFooter">

            <button className="offcanvasButtonFooter" onClick={handleShow}>
              <img className="offcanvasButtonIconFooter" src={icon_more} alt='Logo'></img>
            </button>

            <Offcanvas show={show} onHide={handleClose} placement="bottom" className='offcanvasPanelFooter'>
              <Offcanvas.Header closeButton className='offcanvasHeaderFooter'>
                <Offcanvas.Title className='offcanvasTitleFooter'>

                  {/* Copyright text */}
                  <Link to={routeAbout} onClick={handleClose}><h3 className='rights'>{copyrightText}</h3></Link>

                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <nav className="offcanvas-contentFooter">
                  {/* Change Language Button */}
                  <button className='changeLanguageButton' onClick={handleChangeLanguage}> 
                    <img className="icon" src={icon_language} alt='icon_language'></img> 
                  </button>

                  {/* Contact and social media */}
                  <a href={linkToInstagram} target="_blank" onClick={handleClose} rel="noopener noreferrer"> <img className="icon" src={icon_instagram} alt='icon_instagram'></img> </a>
                  <a href={linkToYoutubeGames} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_youtubeGames} alt='icon_youtubeGames'></img> </a>
                  <a href={linkToYoutubeNet} target="_blank" rel="noopener noreferrer" className="iconRight"> <img className="icon" src={icon_youtube} alt='icon_youtube'></img> </a>
                  <a href={linkToFacebook} target="_blank" onClick={handleClose} rel="noopener noreferrer"> <img className="icon" src={icon_facebook} alt='icon_facebook'></img> </a>
                  <a href={linkToTwitter} target="_blank" onClick={handleClose} rel="noopener noreferrer"> <img className="icon" src={icon_twitter} alt='icon_twitter'></img> </a>
                  <a href={linkToEmail} onClick={handleClose}> <img className="icon" src={icon_email} alt='icon_email'></img> </a>
                </nav>
              </Offcanvas.Body>
            </Offcanvas>

          </div>

        </footer>
      </MiniScreen>
    </div>
  );
}

export default Footer;