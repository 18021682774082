import './Header.scss';
import logo_Full_128 from '../Assets/Images/logo_Full_128.png';
import logo_Mini_128 from '../Assets/Images/logo_Mini_128.png'
import icon_menu from '../Assets/Images/icon_menu.png';
import { Link, useLocation } from 'react-router-dom';
import { headerButtons, routeAssets, routeGames, routeTermsOfUse, routePrivacy, routeApps } from '../Envars';
import { FullScreen, MidScreen, MiniScreen } from '../reactResponsive';
import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';

function Header() {


  const location = useLocation().pathname;

  // Offcanvas
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <FullScreen>
        <header className='stickyTop fullScreen'>

          <Link to={routeGames}>
            <img className="logo" src={logo_Full_128} alt='Logo'></img>
          </Link>
          
          <nav>
            <Link className={(location.startsWith(routeGames))? 'Selected' : 'NoSelected'} to={routeGames}>{headerButtons.games}</Link>
            <Link className={(location.startsWith(routeApps))? 'Selected' : 'NoSelected'} to={routeApps}>{headerButtons.apps}</Link>
            <Link className={(location.startsWith(routeAssets))? 'Selected' : 'NoSelected'} to={routeAssets}>{headerButtons.assets}</Link>
            <Link className={(location.startsWith(routeTermsOfUse))? 'Selected' : 'NoSelected'} to={routeTermsOfUse}>{headerButtons.termsOfUse}</Link>
            <Link className={(location.startsWith(routePrivacy))? 'Selected' : 'NoSelected'} to={routePrivacy}>{headerButtons.privacy}</Link>
          </nav>
        </header>
      </FullScreen>
      <MidScreen>
        <header className='stickyTop miniScreen'>

          <Link to={routeGames}>
              <img className="logo" src={logo_Full_128} alt='Logo'></img>
          </Link>

          <div className="offcanvasCustomHeader">

            <button className="offcanvasButtonHeader" onClick={handleShow}>
              <img className="offcanvasButtonIconHeader" src={icon_menu} alt='Logo'></img>
            </button>

            <Offcanvas show={show} onHide={handleClose} placement="end" className='offcanvasPanelHeader'>
              <Offcanvas.Header closeButton closeVariant='white'>
                <Offcanvas.Title className='offcanvasTitleHeader'>MENU</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <nav className="offcanvas-contentHeader">
                  <Link className={(location.startsWith(routeGames))? 'Selected' : 'NoSelected'} to={routeGames} onClick={handleClose}>{headerButtons.games}</Link>
                  <Link className={(location.startsWith(routeApps))? 'Selected' : 'NoSelected'} to={routeApps} onClick={handleClose}>{headerButtons.apps}</Link>
                  <Link className={(location.startsWith(routeAssets))? 'Selected' : 'NoSelected'} to={routeAssets} onClick={handleClose}>{headerButtons.assets}</Link>
                  <Link className={(location.startsWith(routeTermsOfUse))? 'Selected' : 'NoSelected'} to={routeTermsOfUse} onClick={handleClose}>{headerButtons.termsOfUse}</Link>
                  <Link className={(location.startsWith(routePrivacy))? 'Selected' : 'NoSelected'} to={routePrivacy} onClick={handleClose}>{headerButtons.privacy}</Link>
                </nav>
              </Offcanvas.Body>
            </Offcanvas>

          </div>

        </header>
      </MidScreen>
      <MiniScreen>
        <header className='stickyTop miniScreen'>

          <Link to={routeGames}>
              <img className="logo" src={logo_Mini_128} alt='Logo'></img>
          </Link>
          
          
          <div className="offcanvasCustomHeader">

            <button className="offcanvasButtonHeader" onClick={handleShow}>
              <img className="offcanvasButtonIconHeader" src={icon_menu} alt='Logo'></img>
            </button>

            <Offcanvas show={show} onHide={handleClose} placement="end" className='offcanvasPanelHeader'>
              <Offcanvas.Header closeButton closeVariant='white'>
                <Offcanvas.Title className='offcanvasTitleHeader'>MENU</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <nav className="offcanvas-contentHeader">
                <Link className={(location.startsWith(routeGames))? 'Selected' : 'NoSelected'} to={routeGames} onClick={handleClose}>{headerButtons.games}</Link>
                  <Link className={(location.startsWith(routeApps))? 'Selected' : 'NoSelected'} to={routeApps} onClick={handleClose}>{headerButtons.apps}</Link>
                  <Link className={(location.startsWith(routeAssets))? 'Selected' : 'NoSelected'} to={routeAssets} onClick={handleClose}>{headerButtons.assets}</Link>
                  <Link className={(location.startsWith(routeTermsOfUse))? 'Selected' : 'NoSelected'} to={routeTermsOfUse} onClick={handleClose}>{headerButtons.termsOfUse}</Link>
                  <Link className={(location.startsWith(routePrivacy))? 'Selected' : 'NoSelected'} to={routePrivacy} onClick={handleClose}>{headerButtons.privacy}</Link>
                </nav>
              </Offcanvas.Body>
            </Offcanvas>

          </div>

        </header>
      </MiniScreen>
    </div>
  );
}

export default Header;